import { gql, useMutation } from "@apollo/client";
import { SquareRounded } from "@mui/icons-material";
import { Button, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Event } from "./types";

const statusStyleDefault = {
  backgroundColor: "#fff",
  border: "1px solid #F0F2F8",
  color: "#777777",
  "&:hover, &:focus": {
    backgroundColor: "#fff",
    border: "1px solid #F0F2F8",
    color: "#777777",
  },
};

const statusStyleGreen = {
  backgroundColor: "#FDEFBC",
  border: "1px solid #FDEFBC",
  color: "#333333",
  "&:hover, &:focus": {
    backgroundColor: "#FDEFBC",
    border: "1px solid #FDEFBC",
    color: "#333333",
  },
};

const statusStyleYellow = {
  backgroundColor: "#BFF1C7",
  border: "1px solid #BFF1C7",
  color: "#333333",
  "&:hover, &:focus": {
    backgroundColor: "#BFF1C7",
    border: "1px solid #BFF1C7",
    color: "#333333",
  },
};

// TASK: move this to file with gql collection
const SET_STATUS = gql`
  mutation SetEventStatus($abeId: ID, $status: String) {
    setEventStatus(ABE_ID: $abeId, Status: $status) {
      ABE_ID
    }
  }
`;

const FeedItemStatusMenu: React.FC<{ data: Event; onUpdate: () => void }> = ({
  data,
  onUpdate,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [setEventStatus, { loading }] = useMutation(SET_STATUS);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (data.Type === "ALERT" || data.Type === "WARNING") {
      setAnchorEl(event.currentTarget);
    }
  };
  const [style, setStyle] = useState(statusStyleDefault);

  useEffect(() => {
    switch (data.Status_ID) {
      case "1":
        setStyle(statusStyleDefault);
        break;
      case "2":
        setStyle(statusStyleGreen);
        break;
      case "3":
        setStyle(statusStyleYellow);
        break;
    }
  }, [data]);

  const handleChange = async (status: string) => {
    handleClose();

    setEventStatus({
      variables: { abeId: data.ABE_ID, status: status },
    }).then((res) => {
      onUpdate();
    });

    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClick}
        disableElevation={true}
        color="inherit"
        disabled={loading}
        sx={{
          ...style,
          borderRadius: "5px",
          fontWeight: "400",
          fontSize: 14,
          minWidth: 108,
        }}
      >
        {data.Status}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={() => handleChange("Ny")}>
          <SquareRounded sx={{ mr: 1, fontSize: 16, color: "#F0F2F8" }} /> Ny
        </MenuItem>
        <MenuItem onClick={() => handleChange("Arbejder-på-den")}>
          <SquareRounded sx={{ mr: 1, fontSize: 16, color: "#FDEFBC" }} />
          Arbejder-på-den
        </MenuItem>
        <MenuItem onClick={() => handleChange("Løst")}>
          <SquareRounded sx={{ mr: 1, fontSize: 16, color: "#BFF1C7" }} /> Løst
        </MenuItem>
      </Menu>
    </>
  );
};

export default FeedItemStatusMenu;
