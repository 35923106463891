import { useQuery } from "@apollo/client";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { USERS, UserType } from "../../utils/api";
import FilterDateRangePicker from "./FilterDateRangePicker";
import { EventFilter } from "./types";

const SelectStyle = {
  minWidth: 160,
  backgroundColor: "white",
};

const Filters: React.FC<{ onChange: (values: EventFilter) => void }> = ({
  onChange,
}) => {
  const [values, setValues] = useState<EventFilter>({
    typeID: 0,
    userID: 0,
    from: null,
    to: null,
  });

  const { data: usersData } = useQuery<{ users: UserType[] }>(USERS);

  useEffect(() => {
    onChange(values);
    // FIXME: useEffect dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <>
      <Grid
        container
        columnSpacing={2}
        rowSpacing={1}
        justifyContent="flex-end"
      >
        <Grid item>
          {/* filter by event type */}
          <FormControl size="small" sx={{ ...SelectStyle, minWidth: 100 }}>
            <InputLabel sx={{ fontSize: 13 }}>Type</InputLabel>
            <Select
              defaultValue={0}
              sx={{ borderRadius: "5px", fontSize: 14 }}
              label="Type"
              onChange={(e) => {
                setValues((val) => {
                  return {
                    ...val,
                    typeID: parseInt(e.target.value.toString()),
                  };
                });
              }}
            >
              <MenuItem value={0}>Alle</MenuItem>
              <MenuItem value={3}>INFO</MenuItem>
              <MenuItem value={1}>WARNING</MenuItem>
              <MenuItem value={2}>ALERT</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* filter by user */}
        <Grid item>
          <FormControl size="small" sx={{ ...SelectStyle }}>
            <InputLabel sx={{ fontSize: 13 }}>Bruger</InputLabel>
            <Select
              defaultValue={0}
              sx={{ borderRadius: "5px", fontSize: 14 }}
              label="Bruger"
              onChange={(e) => {
                setValues((val) => {
                  return {
                    ...val,
                    userID: parseInt(e.target.value.toString()),
                  };
                });
              }}
            >
              <MenuItem value={0}>Alle</MenuItem>
              {usersData?.users.map((user, i) => (
                <MenuItem key={i} value={user.U_ID}>
                  {user.Navn}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* filter by date range */}
        <Grid item>
          <FilterDateRangePicker
            onChange={(from: Date | null, to: Date | null) => {
              setValues((val) => {
                return {
                  ...val,
                  from,
                  to,
                };
              });
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Filters;
