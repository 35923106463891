import { Person } from "@mui/icons-material";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import React from "react";
import useAuth from "../../auth/useAuth";

const HeaderLogin = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { user, signOut } = useAuth();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack direction="row" justifyContent="flex-end">
      <Button sx={{ display: "flex", color: "#3F5265" }} onClick={handleClick}>
        <Avatar alt="">
          <Person />
        </Avatar>
        <Typography
          component="span"
          sx={{
            fontSize: { lg: 24, xs: 20 },
            ml: 2,
            fontWeight: 500,
            textTransform: "none",
          }}
        >
          {user?.Navn}
        </Typography>
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.35))",
            mt: 1.5,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        <MenuItem onClick={handleClose}>Vis mine opgaver</MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            signOut();
          }}
        >
          Log ud
        </MenuItem>
      </Menu>
    </Stack>
  );
};

export default HeaderLogin;
