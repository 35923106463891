import { gql, useQuery } from "@apollo/client";
import { Box, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import FeedItem from "./FeedItem";
import Filters from "./Filters";
import { Event, EventFilter } from "./types";

const EVENTS = gql`
  query Events {
    events {
      ABE_ID
      ABET_ID
      EventTime
      Description
      Status
      Status_ID
      Type
      Type_ID
      B_ID
      U_ID
      A_ID
      activityboard_event_type {
        ABET_ID
        Name
        CanBeAssigned
        ColorInfo
        ColorWarning
        ColorAlert
      }
      user {
        U_ID
        Navn
      }
    }
  }
`;

const Feed = () => {
  const { data: eventsData, refetch } = useQuery<{ events: Event[] }>(EVENTS);

  const [eventList, setEventList] = useState<Event[]>([]);

  useEffect(() => {
    if (eventsData) {
      setEventList(eventsData.events);
    }
  }, [eventsData]);

  const handleFilter = (filter: EventFilter) => {
    setEventList(() => {
      if (!eventsData) {
        return [];
      }

      return eventsData.events.filter((item) => {
        if (filter.typeID !== 0) {
          if (filter.typeID !== Number(item.Type_ID)) {
            return false;
          }
        }
        if (filter.userID !== 0) {
          if (filter.userID !== Number(item.user?.U_ID)) {
            return false;
          }
        }

        // validate and filter date.
        const date = new Date(item.EventTime);
        if (date) {
          if (
            filter.from &&
            filter.from.getTime() &&
            date.getTime() < filter.from.getTime()
          ) {
            return false;
          }
          if (
            filter.to &&
            filter.to.getTime() &&
            date.getTime() > filter.to.getTime()
          ) {
            return false;
          }
        }
        // if (
        //   filter.from &&
        //   filter.to &&
        //   filter.from.getTime() &&
        //   filter.to.getTime() &&
        //   date
        // ) {
        //   if (
        //     date.getTime() >= filter.from.getTime() &&
        //     date.getTime() <= filter.to.getTime()
        //   ) {
        //     return true;
        //   } else {
        //     return false;
        //   }
        // }
        return true;
      });
    });
  };

  const handleUpdate = () => {
    refetch();
  };

  return (
    <Box minHeight={320}>
      {/* TODO: Use context for the filter */}
      {/* Header */}
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        sx={{ mb: 3 }}
        spacing={1}
      >
        <Box mb={2} flexShrink={0}>
          <Typography variant="h2">Aktivitets feed</Typography>
        </Box>
        <Box sx={{ ml: { xs: 0, sm: "auto" } }}>
          <Filters
            onChange={(filter) => {
              handleFilter(filter);
            }}
          />
        </Box>
      </Stack>

      <Paper
        sx={{
          p: {
            xs: 2,
            sm: 3,
            md: 4,
            lg: 5,
          },
          borderRadius: 2,
          border: "2px solid #F4F5F5",
          boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.05)",
        }}
      >
        {/* Event list */}
        {eventList.map((data, i) => (
          <Box
            key={i}
            sx={{
              mb: 1.5,
            }}
          >
            {/* add space if the type is info but the previous one is no info */}
            {i > 0 &&
              data.Type === "INFO" &&
              eventList[i - 1].Type !== "INFO" && <Box pt={3} />}

            <FeedItem data={data} onUpdate={handleUpdate} />
          </Box>
        ))}
      </Paper>
    </Box>
  );
};

export default Feed;
