import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Box, CircularProgress } from "@mui/material";
import Cookies from "js-cookie";
import React, { createContext, ReactNode, useEffect, useReducer } from "react";
import { UserType } from "../utils/api";

export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

const INITIALIZE = "INITIALIZE";
const SIGN_OUT = "SIGN_OUT";

export type AuthState = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: UserType | null;
};

export type AuthContextType = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: UserType | null;
  checkAuth: () => Promise<void>;
  signOut: () => Promise<void>;
};

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    user: UserType | null;
  };
  [SIGN_OUT]: undefined;
};

const AUTH = gql`
  query Auth($token: String) {
    token(token: $token) {
      valid
      user {
        U_ID
        Titel
        Navn
      }
    }
  }
`;

const SYSTEM_DOMAIN = gql`
  query SYS {
    system {
      system_domain
    }
  }
`;

const LOGOUT = gql`
  mutation Logout($token: String) {
    logout(token: $token)
  }
`;

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const AuthReducer = (
  state: AuthState,
  action: ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>]
) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
};

const AuthContext = createContext<AuthContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const [getUser] =
    useLazyQuery<{ token: { valid: boolean; user: UserType }; system: any }>(
      AUTH
    );
  const [getSystemDomain] = useLazyQuery<{ system: any }>(SYSTEM_DOMAIN);
  const [logout] = useMutation(LOGOUT);

  useEffect(() => {
    checkAuth();
    const initialize = () => {
      // check auth with interval
      let timer = setInterval(() => {
        checkAuth();
      }, 10000);
      return () => clearInterval(timer);
    };
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkAuth = async (): Promise<void> => {
    const token = getToken();

    await getUser({
      variables: {
        token,
      },
    })
      .then((response) => {
        if (response.data?.token.valid) {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user: response.data?.token.user,
            },
          });

          return;
        } else {
          signOut();
        }
      })
      .catch(() => {
        signOut();
      });
  };

  // get token from cookie
  const getToken = (): string => {
    const cookieEstatetool = Cookies.get("estatetool");
    if (cookieEstatetool) {
      const [, token] = cookieEstatetool.split("token=");
      return token;
    }
    return "";
  };

  const signOut = async () => {
    const token = getToken();

    dispatch({ type: SIGN_OUT });

    // send logout mutation
    await logout({
      variables: {
        token,
      },
    });

    // delete token.
    Cookies.remove("estatetool", { domain: ".estatetool.net" });

    // redirect
    getSystemDomain().then((response) => {
      if (response.data?.system.system_domain) {
        window.location.href = response.data?.system.system_domain;
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        checkAuth,
        signOut,
      }}
    >
      {/* <>{state.isInitialized && state.isAuthenticated && children}</> */}
      <>
        {state.isInitialized && state.isAuthenticated ? (
          children
        ) : (
          <Box
            sx={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </>
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
