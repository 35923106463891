import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateTimePicker from "@mui/lab/DateTimePicker";
import DesktopDateTimePicker from "@mui/lab/DesktopDateTimePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Grid, TextField } from "@mui/material";
import { da } from "date-fns/locale";
import _ from "lodash";
import React, { useCallback, useEffect, useRef } from "react";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import styled from "styled-components";
import moment from "moment";
import "moment/locale/da";

const StyledDatetime = styled(Datetime)`
  color: #333333;

  .rdtPicker {
    right: 0;
  }
  .rdtSwitch {
    cursor: pointer;
  }
`;

const FilterDateRangePicker: React.FC<{
  onChange: (from: Date | null, to: Date | null) => void;
}> = ({ onChange }) => {
  const [from, setFrom] = React.useState<Date | null>(null);
  const [to, setTo] = React.useState<Date | null>(null);
  const [validRange, setValidRange] = React.useState(true);

  const updateRef = useRef(
    _.debounce(async (from: Date | null, to: Date | null) => {
      onChange(from, to);
    }, 1000)
  );

  const validateRange = useCallback(() => {
    if (!from || !to) {
      setValidRange(true);
      return;
    }

    if (moment(from).isSameOrAfter(moment(to))) {
      setValidRange(false);
    } else {
      setValidRange(true);
    }
  }, [from, to]);

  useEffect(() => {
    validateRange();
    updateRef.current.cancel();
    if (validRange) {
      updateRef.current(from, to);
    }
    // onChange(from, to);
  }, [to, from, validRange, validateRange]);

  const validFrom = useCallback(
    (current) => {
      if (!to) {
        return true;
      }
      // validate date.
      return current.startOf("day").isSameOrBefore(moment(to).startOf("day"));
    },
    [to]
  );

  const validTo = useCallback(
    (current) => {
      if (!from) {
        return true;
      }
      // validate date.
      return current.startOf("day").isSameOrAfter(moment(from).startOf("day"));
    },
    [from]
  );

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={6}>
        {/* TODO: range */}
        {/* TODO: custom calendar styles */}
        <StyledDatetime
          locale="da"
          timeFormat="HH:mm"
          isValidDate={validFrom}
          value={from ? from : ""}
          onChange={(date) => {
            if (typeof date === "object") {
              setFrom(date.toDate());
            } else {
              setFrom(null);
            }
          }}
          renderInput={(props) => (
            <TextField
              label="Fra"
              variant="outlined"
              size="small"
              color={validRange ? undefined : "error"}
              focused={!validRange}
              sx={{
                width: 180,
                maxWidth: "100%",
                backgroundColor: "white",
                "& label": {
                  fontSize: 14,
                },
                "& input": {
                  fontSize: 13,
                  height: 19,
                },
              }}
              inputProps={props}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <StyledDatetime
          locale="da"
          timeFormat="HH:mm"
          isValidDate={validTo}
          onChange={(date) => {
            if (typeof date === "object") {
              setTo(date.toDate());
            } else {
              setTo(null);
            }
          }}
          renderInput={(props) => (
            <TextField
              label="Til"
              variant="outlined"
              size="small"
              color={validRange ? undefined : "error"}
              focused={!validRange}
              sx={{
                width: 180,
                maxWidth: "100%",
                backgroundColor: "white",
                "& label": {
                  fontSize: 14,
                },
                "& input": {
                  fontSize: 13,
                  height: 19,
                },
              }}
              inputProps={props}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

/**
 * @deprecated
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FilterDateRangePickerX: React.FC<{
  onChange: (from: Date | null, to: Date | null) => void;
}> = ({ onChange }) => {
  const [from, setFrom] = React.useState<Date | null>(null);
  const [to, setTo] = React.useState<Date | null>(null);

  const updateRef = useRef(
    _.debounce(async (from: Date | null, to: Date | null) => {
      onChange(from, to);
    }, 1000)
  );

  useEffect(() => {
    updateRef.current.cancel();
    updateRef.current(from, to);
    // onChange(from, to);
  }, [to, from]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={da}>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          <DesktopDateTimePicker
            label="Fra"
            clearable
            showToolbar={true}
            value={from}
            onChange={(newValue) => {
              setFrom(newValue);
            }}
            maxDateTime={to}
            renderInput={(props) => (
              <TextField
                {...props}
                size="small"
                sx={{
                  width: 180,
                  maxWidth: "100%",
                  backgroundColor: "white",
                  "& label": {
                    fontSize: 14,
                  },
                  "& input": {
                    fontSize: 13,
                    height: 19,
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <DateTimePicker
            label="Til"
            clearable
            value={to}
            onChange={(newValue) => {
              setTo(newValue);
            }}
            minDateTime={from}
            renderInput={(props) => (
              <TextField
                {...props}
                size="small"
                sx={{
                  width: 180,
                  maxWidth: "100%",
                  backgroundColor: "white",
                  "& label": {
                    fontSize: 14,
                  },
                  "& input": {
                    fontSize: 13,
                    height: 19,
                  },
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default FilterDateRangePicker;
