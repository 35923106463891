import { gql, useQuery } from "@apollo/client";
import {
  ArrowDownward,
  ArrowDownwardRounded,
  ArrowUpward,
  ArrowUpwardRounded,
} from "@mui/icons-material";
import {
  Box,
  Collapse,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

type FilterType = {
  ABF_ID: string;
  Name: string;
  HoursFromNow: number;
};

type KpiActivityBoardType = {
  ABKPI_ID: string;
  ValueForHoursFromNow: number;
  Value: number;
  DeltaValue: number;
  Description: string;
};

type KpiType = {
  ABKPI_ID: string;
  Name: string;
  Description: string;
  activityboard_kpi_values: KpiActivityBoardType[];
};

const Filter = gql`
  query GetKPIFilter {
    filters {
      ABF_ID
      Name
      HoursFromNow
    }
  }
`;

const KpiByValue = gql`
  query KPIByValue($value: Int) {
    kpiByValueForHoursFromNow(ValueForHoursFromNow: $value) {
      ABKPI_ID
      Name
      Description
      activityboard_kpi_values {
        ABKPI_ID
        ValueForHoursFromNow
        Value
        DeltaValue
        Description
      }
    }
  }
`;

const KpiItem: React.FC<{ data: KpiType }> = ({ data }) => {
  const value: number = data.activityboard_kpi_values[0]
    ? data.activityboard_kpi_values[0].Value
    : 0;
  const deltaValue: number = data.activityboard_kpi_values[0]
    ? data.activityboard_kpi_values[0].DeltaValue
    : 0;
  const deltaColor =
    deltaValue > 0 ? "#81DB94" : deltaValue < 0 ? "#E98484" : "#777";

  return (
    <Box>
      {/* title */}
      <Typography mb={0.5} fontSize={12}>
        {data.Name}
      </Typography>
      <Stack direction="row" alignItems="flex-start">
        {/* value */}
        <Typography
          fontWeight={700}
          fontSize={{ md: 36, xs: 28 }}
          lineHeight={1}
        >
          {value}
        </Typography>
        {/* delta value */}
        <Stack direction="row" ml={0.75} mt={0.5} alignItems="center">
          {/* arrow and color */}
          {deltaValue > 0 ? (
            <ArrowUpwardRounded
              sx={{ fontSize: 16, lineHeight: 1, color: deltaColor }}
            />
          ) : deltaValue < 0 ? (
            <ArrowDownwardRounded
              sx={{ fontSize: 16, lineHeight: 1, color: deltaColor }}
            />
          ) : (
            <></>
          )}
          <Typography
            component="span"
            fontSize={14}
            fontWeight={600}
            lineHeight={1}
            color={deltaColor}
          >
            {deltaValue}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

const Kpi = () => {
  const [collapse, setCollapse] = useState(true);
  const [selected, setSelected] = useState<number>(1);

  const { data: filterData } = useQuery<{ filters: FilterType[] }>(Filter);
  const { data, refetch } = useQuery<{ kpiByValueForHoursFromNow: KpiType[] }>(
    KpiByValue,
    {
      variables: {
        value: 1,
      },
    }
  );

  useEffect(() => {
    if (filterData && filterData.filters) {
      setSelected(filterData.filters[0].HoursFromNow);
    }
  }, [filterData]);

  useEffect(() => {
    refetch({
      value: selected,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <Box>
      {/* Header with clickable arroe icon at right to show/hide the panel */}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        mb={3}
      >
        <div>
          <Typography variant="h1" component="h2">
            KPI
          </Typography>
        </div>
        <Stack direction="row">
          <Box display={collapse ? "block" : "none"}>
            <FormControl size="small" sx={{ minWidth: 120, mr: 3 }}>
              <InputLabel sx={{ fontSize: 13 }}>Vis for</InputLabel>
              {/* dynamic filter options except HoursFromNow = 0 */}
              <Select
                value={selected}
                onChange={(event) =>
                  setSelected(parseInt(event.target.value.toString()))
                }
                sx={{
                  borderRadius: "5px",
                  backgroundColor: "#ffffff",
                  fontSize: 14,
                }}
                label="Bruger"
                displayEmpty={true}
              >
                {filterData ? (
                  // .filter((data) => data.HoursFromNow !== 0)
                  filterData.filters.map((data, i) => (
                    <MenuItem key={i} value={data.HoursFromNow}>
                      {data.Name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value={1}>1</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
          <IconButton onClick={() => setCollapse(!collapse)}>
            {collapse ? <ArrowUpward /> : <ArrowDownward />}
          </IconButton>
        </Stack>
      </Stack>

      {/* the collapsable panel */}
      <Collapse in={collapse}>
        <Paper
          sx={{
            px: {
              xs: 2,
              sm: 3,
              md: 4,
              lg: 5,
            },
            py: {
              xs: 1.25,
              md: 1.5,
            },
            borderRadius: 2,
            border: "2px solid #F4F5F5",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.05)",
          }}
        >
          {/* grid items */}
          <Grid container columnSpacing={{ lg: 6, md: 4, xs: 3 }}>
            {data &&
              data.kpiByValueForHoursFromNow.map((data, i) => (
                <React.Fragment key={i}>
                  <Grid item xs={6} md={3} sm={4} sx={{ my: { md: 3, xs: 2 } }}>
                    <KpiItem data={data} />
                  </Grid>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      display: {
                        md: (i + 1) % 4 === 0 ? "none" : "block",
                        sm: (i + 1) % 3 === 0 ? "none" : "block",
                        xs: (i + 1) % 2 === 0 ? "none" : "block",
                      },
                      ml: "-1px",
                      alignSelf: "center",
                      height: 84,
                      borderColor: "#F4F5F5",
                      "&:last-child": {
                        display: "none",
                      },
                    }}
                  />
                </React.Fragment>
              ))}
          </Grid>
        </Paper>
      </Collapse>
    </Box>
  );
};

export default Kpi;
