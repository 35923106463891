import { ApolloClient, InMemoryCache, gql } from "@apollo/client";

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL,
  cache: new InMemoryCache(),
});

export type UserType = {
  U_ID: string;
  Navn: string;
  Titel: string;
  Mobil: string;
};

export const USERS = gql`
  query Users {
    users {
      U_ID
      Navn
      Titel
      Mobil
    }
  }
`;

export default client;
