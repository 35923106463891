import { gql, useMutation } from "@apollo/client";
import { AccountCircleOutlined, MoreVert } from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import React from "react";
import FeedItemStatusMenu from "./FeedItemStatusMenu";
import SelectUserDialog from "./SelectUserDialog";
import { Event } from "./types";

// TASK: move this to file with gql collection
const ASSIGN_USER = gql`
  mutation AssignUserToEvent($abeId: ID, $uId: ID) {
    assignUserToEvent(ABE_ID: $abeId, U_ID: $uId) {
      ABE_ID
    }
  }
`;

const filterDate = (date: string): string => {
  let formattedDate = "";
  if (!date) {
    return formattedDate;
  }

  return format(new Date(date), "MMMM dd, yyyy 'KL' HH:mm");
};

const FeedItemMenu: React.FC<{ data: Event; onUpdate: () => void }> = ({
  data,
  onUpdate,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [openSelectUser, setOpenSelectUser] = React.useState(false);

  const [assignUser] = useMutation(ASSIGN_USER);

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        onClick={handleClick}
        sx={{
          backgroundColor: "#fff",
          border: "1px solid #F0F2F8",
          borderRadius: "5px",
          px: 0.5,
        }}
      >
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleClose}>Se enhed</MenuItem>
        <MenuItem onClick={handleClose}>Se medlem</MenuItem>
        {(data.Type === "WARNING" || data.Type === "ALERT") && (
          <MenuItem
            onClick={() => {
              handleClose();
              setOpenSelectUser(true);
            }}
          >
            Sæt sagsbehandler
          </MenuItem>
        )}
      </Menu>

      {/* select user dialog */}
      <SelectUserDialog
        onClose={(value: string) => {
          setOpenSelectUser(false);
          if (value && data.user?.U_ID !== value) {
            // assign user to event.
            assignUser({
              variables: { abeId: data.ABE_ID, uId: value },
            }).then(() => {
              onUpdate();
            });
          }
        }}
        selectedValue={data.user?.U_ID ? data.user?.U_ID : ""}
        open={openSelectUser}
      />
    </>
  );
};

const FeedItem: React.FC<{ data: Event; onUpdate: () => void }> = ({
  data,
  onUpdate,
}) => {
  return (
    <>
      <Box
        sx={{
          p: 2,
          pl: 3,
          backgroundColor: "#F9FAFA",
          borderRadius: "5px 5px",
          borderLeft: `5px solid`,
          borderLeftColor: () => {
            if (data.activityboard_event_type) {
              switch (data.Type) {
                case "INFO":
                  return data.activityboard_event_type.ColorInfo;
                case "ALERT":
                  return data.activityboard_event_type.ColorAlert;
                case "WARNING":
                  return data.activityboard_event_type.ColorWarning;
              }
            }
            return "transparent";
          },
        }}
      >
        <Grid container columnSpacing={2} alignItems="center">
          {/* information */}
          <Grid item xs={12} lg={6}>
            <Typography
              sx={{
                mb: 0.5,
                fontSize: 12,
                color: "#496A70",
              }}
            >
              {data.Type} &nbsp;|&nbsp; {filterDate(data.EventTime)}
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                lineHeight: 1.3,
              }}
            >
              {data.Description}
            </Typography>
          </Grid>

          {/* user */}
          <Grid item xs={6} lg={3}>
            {data.user && (
              <Stack direction="row">
                <AccountCircleOutlined />
                <Typography ml={1} fontSize={14}>
                  {data.user?.Navn}
                </Typography>
              </Stack>
            )}
          </Grid>

          {/* actions */}
          <Grid item xs={6} lg={3}>
            <Stack direction="row" justifyContent="flex-end" spacing={1}>
              {/* status dropdown */}
              <FeedItemStatusMenu data={data} onUpdate={onUpdate} />
              {/* menu dropdown */}
              <FeedItemMenu data={data} onUpdate={onUpdate} />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default FeedItem;
