import Kpi from "./Components/kpi/Kpi";
import HeaderLogin from "./Components/header/HeaderLogin";
import Feed from "./Components/feed/Feed";
import { Box, Container } from "@mui/material";
import { ApolloProvider } from "@apollo/client";
import ApolloClient from "./utils/api";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import typography from "./themes/typography";
import { AuthProvider } from "./auth/context";

const theme = createTheme({
  typography: typography,
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={ApolloClient}>
        <AuthProvider>
          <Container sx={{ maxWidth: 1500, pt: 5 }}>
            <HeaderLogin />
            <Box mb={5} />
            <Kpi />
            <Box mb={8} />
            <Feed />
            <Box mb={5} />
          </Container>
        </AuthProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
