import { useQuery } from "@apollo/client";
import { AccountCircleOutlined } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import React from "react";
import { USERS, UserType } from "../../utils/api";

const SelectUserDialog: React.FC<{
  onClose: (value: string) => void;
  selectedValue: string;
  open: boolean;
}> = (props) => {
  const { onClose, selectedValue, open } = props;
  const { data: usersData } = useQuery<{ users: UserType[] }>(USERS);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value: string) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Select user</DialogTitle>
      {/* TODO: make this scrollable */}
      <List sx={{ pt: 0 }}>
        {usersData?.users.map((user, i) => (
          <ListItem
            button
            onClick={() => {
              handleListItemClick(user.U_ID);
            }}
            key={i}
          >
            <ListItemAvatar
              sx={{
                minWidth: 34,
              }}
            >
              <AccountCircleOutlined />
            </ListItemAvatar>
            <ListItemText primary={user.Navn} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

export default SelectUserDialog;
